export const orderStatuses = [
    {
        id: "requested",
        name: "Requested"
    },
    {
        id: "approved",
        name: "Approved"
    },
    {
        id: "declined",
        name: "Declined"
    },
    {
        id: "concluded",
        name: "Concluded"
    }
];
