import {
    ImageField,
    ImageInput,
    Loading,
    useInput,
    useRecordContext,
    useReference
} from "react-admin";
import { useFormContext } from "react-hook-form";

export const UploadInput = ({ source }: { source: string }) => {
    const record = useRecordContext();
    const {
        referenceRecord: upload,
        isFetching,
        error
    } = useReference<{ id: string; url: string }>({
        reference: "uploads",
        id: record[source]
    });

    const { setValue, getValues } = useFormContext();

    if (isFetching) {
        return <Loading />;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    if (upload) {
        const values = getValues();
        const value = values[source];
        if (typeof value === "string") {
            setValue(source, { title: upload.id, src: upload.url });
        }
    }

    return (
        <ImageInput source={source} maxSize={5000000}>
            <ImageField source="src" />
        </ImageInput>
    );
};
