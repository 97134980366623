import {
    AutocompleteInput,
    DateTimeInput,
    Edit,
    ReferenceInput,
    SimpleForm,
    required
} from "react-admin";
import { PageTitle } from "components/navbar/PageTitle";
import { EditToolbar } from "components/toolbar/EditToolbar";
import { DefaultTextInput } from "components/default-text-input";
import { UploadArrayInput } from "components/upload/UploadArrayInput";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";

export const PickUpEdit = () => {
    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) => `${record?.key}`}
                />
            }
        >
            <SimpleForm toolbar={<EditToolbar />}>
                <DefaultTextInput
                    source="description"
                    type="text"
                    multiline
                    validate={[required()]}
                />

                <DynamicWidthAutocompleteReferenceInput
                    validate={[required()]}
                    optionText="name"
                    reference="spots"
                    source="spotId"
                />

                <DateTimeInput source="start" validate={[required()]} />

                <DateTimeInput source="end" validate={[required()]} />

                <UploadArrayInput source="photoIds" />
            </SimpleForm>
        </Edit>
    );
};
