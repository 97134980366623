import {
    BooleanInput,
    Create,
    ImageField,
    ImageInput,
    SimpleForm,
    required
} from "react-admin";
import Typography from "@mui/material/Typography";
import { DefaultTextInput } from "components/default-text-input";

export const UserCreate = () => {
    return (
        <Create redirect="list">
            <SimpleForm>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Create user
                </Typography>

                <DefaultTextInput
                    source="phone"
                    type="phone"
                    label="Phone number"
                    validate={[required()]}
                />

                <DefaultTextInput
                    source="firstName"
                    type="text"
                    validate={[required()]}
                />

                <DefaultTextInput
                    source="lastName"
                    type="text"
                    validate={[required()]}
                />

                <DefaultTextInput source="paymentMethod" type="text" />

                <DefaultTextInput
                    source="paymentDestination"
                    type="text"
                    label="Payment recipient"
                />

                <DefaultTextInput source="about" type="text" multiline />

                <BooleanInput
                    source="isPushNotificationsEnabled"
                    label="Push notifications"
                />

                <BooleanInput
                    source="isSmsNotificationsEnabled"
                    label="SMS notifications"
                />

                <ImageInput source="avatarId" accept="image/*">
                    <ImageField source="src" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};
