import { Edit, NumberInput, SimpleForm, required } from "react-admin";
import { PageTitle } from "components/navbar/PageTitle";
import { EditToolbar } from "components/toolbar/EditToolbar";
import { DefaultTextInput } from "components/default-text-input";
import { UploadArrayInput } from "components/upload/UploadArrayInput";

export const SpotEdit = () => {
    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) => `${record?.key}`}
                />
            }
        >
            <SimpleForm toolbar={<EditToolbar />}>
                <DefaultTextInput
                    source="name"
                    type="text"
                    validate={[required()]}
                />

                <DefaultTextInput
                    source="description"
                    type="text"
                    multiline
                    validate={[required()]}
                />

                <NumberInput
                    source="latitude"
                    label="Latitude"
                    validate={[required()]}
                />
                <NumberInput
                    source="longitude"
                    label="Longitude"
                    validate={[required()]}
                />

                <UploadArrayInput source="photoIds" />
            </SimpleForm>
        </Edit>
    );
};
