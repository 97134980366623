import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Edit,
    ReferenceArrayInput,
    ReferenceInput,
    SimpleForm,
    required
} from "react-admin";
import { PageTitle } from "components/navbar/PageTitle";
import { EditToolbar } from "components/toolbar/EditToolbar";
import { DefaultTextInput } from "components/default-text-input";
import { UploadArrayInput } from "components/upload/UploadArrayInput";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";

export const ItemEdit = () => {
    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) => `${record?.key}`}
                />
            }
        >
            <SimpleForm toolbar={<EditToolbar />}>
                <DefaultTextInput
                    source="title"
                    type="text"
                    validate={[required()]}
                />

                <DefaultTextInput
                    source="description"
                    type="text"
                    multiline
                    validate={[required()]}
                />

                <DynamicWidthAutocompleteReferenceInput
                    optionText="name"
                    reference="users"
                    source="userId"
                    readOnly
                />

                <ReferenceArrayInput source="tagIds" reference="tags" alwaysOn>
                    <AutocompleteArrayInput
                        filterToQuery={(tag) => ({ title: tag })}
                        optionText="title"
                        label="Tags"
                    />
                </ReferenceArrayInput>

                <UploadArrayInput source="photoIds" />
            </SimpleForm>
        </Edit>
    );
};
