import {
    AutocompleteArrayInput,
    BooleanInput,
    CloneButton,
    DateTimeInput,
    Edit,
    NumberInput,
    ReferenceArrayInput,
    SelectInput,
    SimpleForm,
    WithRecord,
    required
} from "react-admin";
import { PageTitle } from "components/navbar/PageTitle";
import { EditToolbar } from "components/toolbar/EditToolbar";
import { DefaultTextInput } from "components/default-text-input";
import { unitTypes } from "constants/unit-types";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";

export const SellOfferEdit = () => {
    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) => `${record?.key}`}
                />
            }
        >
            <SimpleForm
                toolbar={
                    <EditToolbar
                        cloneButton={
                            <WithRecord
                                render={({
                                    id,
                                    pickUpId,
                                    availability,
                                    deadline,
                                    ...record
                                }) => <CloneButton record={record} />}
                            />
                        }
                    />
                }
            >
                <DynamicWidthAutocompleteReferenceInput
                    optionText="name"
                    reference="users"
                    source="userId"
                    readOnly
                />

                <ReferenceArrayInput
                    source="collaboratorIds"
                    reference="users"
                    alwaysOn
                >
                    <AutocompleteArrayInput
                        filterToQuery={(user) => ({ name: user })}
                        optionText="name"
                        label="Collaborators"
                    />
                </ReferenceArrayInput>

                <DynamicWidthAutocompleteReferenceInput
                    optionText="title"
                    reference="items"
                    source="itemId"
                    readOnly
                />

                <SelectInput
                    source="unitType"
                    choices={unitTypes}
                    optionValue="id"
                    readOnly
                />
                <DefaultTextInput source="unitDescription" readOnly multiline />
                <NumberInput source="unitPrice" readOnly />

                <DynamicWidthAutocompleteReferenceInput
                    optionText="name"
                    reference="pick-ups"
                    source="pickUpId"
                    readOnly
                />

                <NumberInput source="availability" validate={[required()]} />

                <DateTimeInput source="deadline" validate={[required()]} />
                <BooleanInput source="isActive" validate={[required()]} />
            </SimpleForm>
        </Edit>
    );
};
