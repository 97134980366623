import {
    FormDataConsumer,
    ImageField,
    ImageInput,
    ReferenceField,
    ReferenceArrayField,
    useReference,
    useRecordContext,
    Loading,
    useReferenceArrayFieldController,
    useGetMany
} from "react-admin";
import { useFormContext } from "react-hook-form";

export const UploadArrayInput = ({ source }: { source: string }) => {
    const record = useRecordContext();

    const {
        data: uploads,
        isFetching,
        error
    } = useGetMany(
        "uploads",
        {
            ids: record[source]
        },
        {}
    );

    const { setValue, getValues } = useFormContext();

    if (isFetching) {
        return <Loading />;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    if (uploads) {
        const values = getValues();
        const value = values[source];
        if (
            Array.isArray(value) &&
            value.some((upload) => typeof upload === "string")
        ) {
            setValue(
                source,
                value.map((id) => {
                    const upload = uploads.find((upload) => upload.id === id);
                    return { title: upload.id, src: upload.url, id: upload.id };
                })
            );
        }
    }

    return (
        <ImageInput source={source} maxSize={5000000} multiple>
            <ImageField source="src" />
        </ImageInput>
    );
};
