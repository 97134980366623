import {
    Create,
    ImageField,
    ImageInput,
    SimpleForm,
    required
} from "react-admin";
import Typography from "@mui/material/Typography";
import { DefaultTextInput } from "components/default-text-input";

export const TagCreate = () => {
    return (
        <Create redirect="list">
            <SimpleForm>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Create tag
                </Typography>

                <DefaultTextInput
                    source="title"
                    type="text"
                    validate={[required()]}
                />

                <DefaultTextInput
                    source="color"
                    type="text"
                    validate={[required()]}
                />

                <ImageInput source="iconId" accept="image/*">
                    <ImageField source="src" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};
