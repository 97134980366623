import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Create,
    ImageField,
    ImageInput,
    ReferenceArrayInput,
    ReferenceInput,
    SimpleForm,
    required
} from "react-admin";
import Typography from "@mui/material/Typography";
import { DefaultTextInput } from "components/default-text-input";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";

export const ItemCreate = () => {
    return (
        <Create redirect="list">
            <SimpleForm>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Create item
                </Typography>

                <DefaultTextInput
                    source="title"
                    type="text"
                    validate={[required()]}
                />

                <DefaultTextInput
                    source="description"
                    type="text"
                    multiline
                    validate={[required()]}
                />

                <DynamicWidthAutocompleteReferenceInput
                    validate={[required()]}
                    optionText="name"
                    reference="users"
                    source="userId"
                />

                <ReferenceArrayInput source="tags" reference="tags" alwaysOn>
                    <AutocompleteArrayInput
                        filterToQuery={(tag) => ({ title: tag })}
                        optionText="title"
                        label="Tags"
                        validate={[required()]}
                    />
                </ReferenceArrayInput>

                <ImageInput source="photoIds" accept="image/*" multiple>
                    <ImageField source="src" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};
